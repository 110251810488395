import axios from 'axios';
import React, { useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import Footer from './Footer';
import Header from './Header';

const LocateBooking = ({ items }) => {
  const [book_id, set_book_id] = useState('');
  const [data, setData] = useState({});

  const handleSubmit = (e) => {
    e.preventDefault();
    axios

      .post('https://backend.catchavibes.com/api/findBooking', {
        cartid: book_id,
      },{
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
      .then((res) => {
         setData((res.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <>
      <Header />
      <Container>
        <h1 className='booking_title'>Locate Booking</h1>
        <div className='row mb-4'>
          <div className='col-12 col-md-6 mb-5 mb-lg-0'>
            <div>
              <h5 className='formheader mb-3'>Enter Booking Details</h5>

              <Form>

                <Form.Group className='mb-3' controlId='formBasicPassword'>
                  <Form.Label>Booking Confirmation No.</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Confirmation Number'
                    onChange={(e) => set_book_id(e.target.value)}
                    value={book_id}
                  />
                </Form.Group>
                <Button
                  type={'submit'}
                  className={'btn btn-primary '}
                  onClick={handleSubmit}
                >
                  LOCATE BOOKING
                </Button>
              </Form>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <h5 className='formheader mb-3 bg-info'>Information</h5>

            {data.length ? (data.map(item => {
              return (
                  <div className="data" key={item.id}>
                        <div>
                          <p>
                            <b>Customer ID:</b> {item?.customer_id?.toUpperCase()}
                          </p>
                          <p>
                            <b>Booking ID :</b> {item?.cart_id?.toUpperCase()}
                          </p>
                          <p>
                            <b>Adults :</b> {item?.adults}
                          </p>
                          <p>
                            <b>Children :</b> {item?.children}
                          </p>
                          <p>
                            <b>Tour Dates :</b>{' '}
                            {new Date(item?.date).toLocaleDateString('en-US', {timeZone: 'UTC'})}
                          </p>
                        </div>
                  </div>
              );
            }))
               : (
                <h3>Enter Confirmation to See Info</h3>
            )}

            <div className='d-flex'>
              <p>
                <b> {data.length? <p>Destinations :</p> : console.log()} </b>
              </p>
              <ol>
                {data.length ? (data.map(item => {
                      return (
                          <div className="data" key={item.id}>
                            <div>
                              <p>
                                {item?.destination.map(dest => {
                                  return(
                                      <div className="data" key={item.id}>
                                        <div>
                                          <p>{dest.destination}</p>
                                        </div>
                                      </div>
                                  )}
                                )}
                              </p>
                            </div>
                          </div>
                      );
                    }))
                    : (
                        <h3></h3>
                    )}
              </ol>
            </div>
            </div>
          </div>
      </Container>

      <Footer />
    </>
  );
};

export default LocateBooking;
