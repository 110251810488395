import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import { Col, Container, Row} from "react-bootstrap";
import {v4 as uuid} from "uuid";

const DATA = [
    {
        id: uuid(),
        image: 'images/general/airbnb_logo.jpg',
        title: 'Airbnb',
        subtitle: 'Luxury 3BR/3.5BA home in Ocho Rios',
        link: 'https://www.airbnb.com/rooms/888492052111042801?source_impression_id=p3_1684174868_0L%2FZTL5g%2F6Ry5wWM',
        linkText: 'Book Stay Now',
    },
    {
        id: uuid(),
        image: 'images/general/booking.jpg',
        title: 'Booking.com',
        subtitle: 'Luxury 3BR/3.5BA home in Ocho Rios',
        link: 'https://www.booking.com/hotel/jm/catch-a-vibes-bolt-tours-and-getaways-pyramid-heights-ocho-rios.html',
        linkText: 'Book Stay Now',
    },
    {
        id: uuid(),
        image: 'images/general/vrbo.jpg',
        title: 'VRBO',
        subtitle: 'Luxury 3BR/3.5BA home in Ocho Rios',
        link: 'https://www.vrbo.com/3344939',
        linkText: 'Book Stay Now',
    }
]

const Hotel = () => {
    return (
        <>
            <Header/>
              <Container>
                  <h1 className='booking_title'>Book Accommodation through our Partner</h1>
                  <Container>
                      <Row>
                          {DATA.map((item) => (
                              <Col>
                                  <div className='col-md-12'>
                                      <div className='card mb-2'>
                                          <img
                                              className='card-img-top'
                                              src={item.image}
                                              height='232'
                                              alt='Card  cap'
                                          />
                                          <div className='card-body'>
                                              <h4 className='card-title'>{item.title}</h4>
                                              <p className='card-text'>{item.subtitle}</p>
                                              <a className='btn btn-primary' href={item.link}>
                                                  {item.linkText}
                                              </a>
                                          </div>
                                      </div>
                                  </div>
                              </Col>
                          ))}
                      </Row>
                  </Container>
              </Container>
            <Footer/>
        </>
    );
};

export default Hotel;

