import React from 'react';
import { Carousel } from 'react-bootstrap';
import './slider.css';
import {useNavigate} from "react-router-dom";


const Slider = () => {
  let navigate = useNavigate();

  return (
    <Carousel>
      <Carousel.Item>
        <img
          className='d-block w-100 slider_image'
          src='./images/slide2/image2.jpg'
          alt='First slide'
          style={{
            background:
              'linear-gradient(0deg, rgba(5, 5, 5, 0.2), rgba(46, 42, 44, 0.2))',
          }}
        />
        <Carousel.Caption style={{ top: '0', bottom: '0' }}>
          <img
            className='text-focus-in'
            src='/images/logo/logobg.png'
            alt=''
            width='100'
          />
          <h2 className='slider-title-2 tracking-in-expand'>
            Catch A Vibes Bolt Tours and Getaways
          </h2>
          <p className='lead tracking-in-expand slider-subtitle '>
            Breathtaking and Memorable Vacation Experiences{' '}
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100 slider_image'
          src='./images/slide2/image3.jpg'
          alt='Second slide'
          style={{
            background:
              'linear-gradient(0deg, rgba(5, 5, 5, 0.2), rgba(46, 42, 44, 0.2))',
          }}
        />

        <Carousel.Caption>
          <h2 className='slider-title  text-focus-in '>
            Accomodation available
          </h2>
          <p className='lead tracking-in-expand-fwd'>
            <button type='button' className='btn btn-primary' onClick={()=>navigate('/hotel')}>
              Book Accomodation Now
            </button>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className='d-block w-100 slider_image'
          src='./images/slide2/image4.jpg'
          alt='Third slide'
          style={{
            background:
              'linear-gradient(0deg, rgba(5, 5, 5, 0.2), rgba(46, 42, 44, 0.2))',
          }}
        />

        <Carousel.Caption>
          <h2 className='slider-title slidetxt text-focus-in '>
            Packages Starting At $180 USD
          </h2>
          <p className='lead tracking-in-expand-fwd'>
            <button type='button' className='btn btn-primary' onClick={()=> navigate('/bookings')}>
              Book Tour Now
            </button>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Slider;
