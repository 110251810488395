/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import VisitedPalaceSlider from './components/Home/VisitedPalaceSlider';
import Footer from './Footer';
import Header from './Header';
import Slider from './slider';
import axios from "axios";
import { Form } from 'react-bootstrap';
const Home = () => {
  let navigate = useNavigate();

  const [destination, setDestinations] = useState();
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [date, setDate] = useState('');
  const [adult, setAdult] = useState(1);
  const [child, setChild] = useState(0);

  const handleSubmit = (event) => {
    event.preventDefault();
    navigate('/bookings', {
      state: {
        destination_s: selectedDestinations,
        adult_s: adult,
        child_s: child,
        date_s: date,
      },
    });
  };

  const headers = {
    headers: {
      'Access-Control-Allow-Headers': '*',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    },
  };

  useEffect(() => {
    axios
        .post(process.env.REACT_APP_DESTINATION_API ,headers)
        .then(function (response) {
          setDestinations(response?.data?.map((i) => ({ ...i, checked: false })));
        })
        .catch(function (error) {
          console.log(error);
        });
  }, []);
  return (
    <>
      <Header />
      <Slider />
      {/*-- ----------------------------------------------- BOOKING SECTION ---------------------------------- --*/}
      <div className='booking '>
        <div className='booking-form container'>
          <form>
            <div className='row no-margin align-items-start align-items-lg-center'>
              <div className='col-lg-4'>
                <div className='form-header'>
                  <h2>Book Now</h2>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='row no-margin'>
                  <div className='col-md-4'>
                    <div className='form-group'>
                      <span className='form-label'>Destination</span>
                      <Form.Select onChange={(e) => setSelectedDestinations(e.target.value)}>
                        {
                          destination?.map((dest) => (
                            <option value={dest.destination_id}>{dest.destination}</option>
                          ))
                        }
                        <option value="all">All</option>
                        <option selected>Choose Destination</option>
                      </Form.Select>

                    </div>
                  </div>

                  <div className='col-md-4'>
                    <div className='form-group'>
                      <span className='form-label'>Tour Date</span>
                      <input
                        className='form-control'
                        type='date'
                        onChange={(e) => setDate(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <span className='form-label'>Adults</span>
                      <select
                        className='form-control'
                        name='adults'
                        value={adult}
                        onChange={(e) => setAdult(e.target.value)}
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                      </select>
                      <span className='select-arrow'></span>
                    </div>
                  </div>

                  <div className='col-md-2'>
                    <div className='form-group'>
                      <span className='form-label'>Children</span>
                      <select
                        className='form-control'
                        name='children'
                        value={child}
                        onChange={(e) => setChild(e.target.value)}
                      >
                        <option>0</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                        <option>6</option>
                      </select>
                      <span className='select-arrow'></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-2 mt-4'>
                <div className='form-btn'>
                  <button
                    className='btn btn-primary'
                    onClick={handleSubmit}
                    type={'button'}
                  >
                    Book Tour
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/*-- -------------------------------------------end  BOOKING SECTION ---------------------------------- --*/}

      {/*-- -------------------------------- Company Introduction ----------------------------------------------- --*/}
      <section className='py-5'>
        <div className='container'>
          <h1 className='web-title1'>Company Introduction</h1>
          <p className='lead'>
            We offer a variety of tour experiences to major destinations
            including Kingston, Montego Bay, Negril, Ocho Rios, and Portland.
            Licensed and certified tour operators and tour guides through The
            Tourist Product Developemnt Company will make your experiences
            memorable and safe. We pride ourselves through differentiation from
            our competitors.
          </p>
        </div>
      </section>
      {/*    -- -------------------------------- End Company Introduction --------------------------------------- --*/}

      {/*  -- --------------------------------------------- Locations---------------------------------------- --*/}

      {/*--Carousel Wrapper-*/}
      <div
        id='multi-item-example'
        className='carousel slide carousel-multi-item services-section'
        data-ride='carousel'
      >
        <div className='container'>
          <h1 className='service-title-text'>Places that we Visit</h1>
          <VisitedPalaceSlider />
        </div>
      </div>
      {/*--/.Carousel Wrapper*/}
      {/*-- ----------------------------------------------End Locations --------------------------------------- --*/}

      {/*-- -------------------------------- Destinations ------------------------------------------------------- --*/}

      <div
        className='container-fluid promobg d-flex justify-content-center align-items-center'
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgba(5, 5, 5, 0.5), rgba(46, 42, 44, 0.5)),url('images/general/offergbg2.jpg')",
        }}
      >
        <div className='deals-section'>
          <p className='web-title2'>Our Tour Destinations</p>
          <p className='web-title3'>
            Kingston - Ocho Rios - Montego Bay - Negril - Portland
          </p>
          <p className='web-text1'>Tour packages starting at </p>
          <p className='web-text2'>$180 USD Per person</p>
        </div>
      </div>
      {/* -- -------------------------------- End Destinations ---------------------------------------------- --*/}

      {/* -- -----------------------------------------services offered ---------------------------------------- --*/}
      <div className='services-section'>
        <div className='container '>
          <p className='service-title-text'>SERVICES OFFERED</p>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <ul className='list-display list-checkmarks'>
                <li>Shuttle From Hotel to Tour Meetup</li>
                <li>Airport Transfers</li>
                <li>Cruise Ship Pick up</li>
                <li>Hotel Transfers</li>
              </ul>
            </div>
            <div className='col-12 col-md-6'>
              <ul className='list-display list-checkmarks'>
                <li>Onsite Cash Payments</li>
                <li>Friendly Chapperone</li>
                <li>Scenic Routes</li>
                <li>Location Narration</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      {/*  -- -----------------------------------------services offered --------------------------------------- --*/}

      {/*-- -----------------------------------------Photo Gallery  ---------------------------------------- --*/}
      <div className='container'>
        <p className='web-title1'>Bolt Tour Image Gallery</p>
        {/*-- Gallery */}
        {/*-- Gallery */}
        <div className='row'>
          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img1.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>
          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img13.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>

          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img9.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>
          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img3.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>

          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img4.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>
          <div className='col-sm-6 col-md-4  mb-4 mb-lg-0'>
            <img
              src='images/gallery/img5.JPEG'
              className='w-100 shadow-1-strong rounded mb-4'
              alt=''
            />
          </div>
        </div>
        {/*-- Gallery */}
        {/*-- Gallery */}
      </div>
      {/*-- ----------------------------------------- End Photo Gallery  ------------------------------------- --*/}
      <Footer />
    </>
  );
};

export default Home;
