import axios from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {Button, ButtonGroup, Col, Container, Form, Modal, Row} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { v4 as uuid } from 'uuid';
import Footer from './Footer';
import Header from './Header';




const Bookings = () => {
  let location = useLocation();
  let navigate = useNavigate();

  const [destinations, setDestinations] = useState([]);
  const [selectedDestinations, setSelectedDestinations] = useState([]);
  const [priceCounter, setPriceCounter] = useState(0);

  /*Client information*/
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [cardname, setCardname] = useState('')

  /*Client card information*/
  const [cardnum, setCardnum] = useState('');
  const [cardmonth, setCardmonth] = useState('');
  const [cardyear, setCardyear] = useState('');
  const [cardcvc, setCardcvc] = useState('');

  /* variable text displayed on booking page*/
  const [adultText, setAdultText] = useState('Adult');
  const [childText, setChildText] = useState('Child');

  /*total of tickets*/
  const [childTotal, setChildTotal] = useState(0);
  const [adultTotal, setAdultTotal] = useState(0);
  const [total, setTotal] = useState(0);

  /*number of tickets*/
  const [adult_tickets, setAdultTickets] = useState(0);
  const [children_tickets, setChildrenTickets] = useState(0);

  /*date of tour*/
  const [date, setDate] = useState();

  const [cash, setCash] = useState(false);
  const [terms, setTerms] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const confirmation = useRef();

  const headers = {
      headers: {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      },
    };

  useEffect(() => {
    if(location.state)
    {
      setAdultTickets(location?.state.adult_s)
      setChildrenTickets(location?.state.child_s)
      setDate(location?.state.date_s)

      if(location?.state.destination_s == 'all')
      {
        setSelectedDestinations([1,2,3,4,5])
      }
      else {
        setSelectedDestinations(location?.state.destination_s)
      }


    }
  })

  const createBooking = () => {
    let cartid = uuid().slice(0, 8);
    let customer_number = uuid().slice(0, 8);
    confirmation.current = cartid;

    axios
      .post(process.env.REACT_APP_CONFIRM, {
        firstname: fname,
        lastname: lname,
        phone: phone,
        email: email,
        childtotal: childTotal,
        adulttotal: adultTotal,
        total: total,
        adulttickets: adult_tickets,
        childrentickets: children_tickets,
        date: new Date(date),
        ccnumber: cardnum,
        cardexpyear: cardyear,
        cardexpmonth: cardmonth,
        CVV: cardcvc,
        cash: cash,
        customer_number: customer_number,
        cartid: cartid,
        destinations: selectedDestinations,
      })
      .then(function (response) {
        navigate('/success', {
          state: { confirmation_s: confirmation.current },
        });
        return;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    axios
      .post(process.env.REACT_APP_DESTINATION_API, {
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Methods': '*',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
      })
      .then(function (response) {
         console.log(response);
        setDestinations(response?.data?.map((i) => ({ ...i, checked: false })));
      })
      .catch(function (error) {
        console.log(error);
      });
  },[]);


  useEffect(() => {
    let count = 0;

    const price = destinations.reduce((acc, cur) => {
      if (selectedDestinations.includes(cur.id)) {
        count += 1;
        acc += cur.cost;
      }
      return acc;
    }, 0);
    console.log(price);
    setPriceCounter(price);
    console.log(count);
  }, [destinations, selectedDestinations]);

  useEffect(() => {
    let adTotal = priceCounter * adult_tickets;
    let chTotal = (priceCounter * children_tickets) / 2;

    let total = adTotal + chTotal;
    // alert(priceCounter)

    setAdultTotal(adTotal);
    setChildTotal(chTotal);
    setTotal(total);
    setAdultText(`Adults   x ${adult_tickets}`);
    setChildText(`Children x ${children_tickets}`);
  }, [adult_tickets, children_tickets, priceCounter]);

  const handleDisabled = () => {
    setDisabled(!disabled);
    setCash((p) => !p);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cash) {
      createBooking();
      navigate('/success', {
        state: { confirmation_s: confirmation.current },
      });
      return;
    }

    if (
      !date ||
      !fname ||
      !lname ||
      !email ||
      !phone ||
      !total ||
      (!cash && (!cardnum || !cardyear || !cardmonth || !cardcvc))
    ) {
      toast.error('Please complete required fields');
      return;
    }

    if (!adult_tickets && !children_tickets) {
      toast.error('Adult or child must have at least 1!');
      return;
    }
    if (selectedDestinations.length === 0) {
      toast.error('Please choose a destination');
      return;
    }

    const dataObj = {
      firstname: fname,
      lastname: lname,
      cardname : cardname,
      phone: phone,
      email: email,
      childtotal: childTotal,
      adulttotal: adultTotal,
      total: total,
      adulttickets: adult_tickets,
      childrentickets: children_tickets,
      date: date,
      ccnumber: cardnum,
      cardexpyear: cardyear,
      cardexpmonth: cardmonth,
      CVV: cardcvc,
      cash: cash,
      destinations: selectedDestinations,
    };


    axios
      .post(process.env.REACT_APP_BOOK, dataObj, headers)
      .then(function (response) {
        console.log(response)
        if(response?.data?.status == 1)
        {
          createBooking()
        }
        else
        {
          toast.error(response?.data?.code + "\n" + response?.data?.message);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  //create spinner
  // useEffect(()=>{
  //   while (!destinations){
  //     return(
  //
  //     )
  //   }

  // },[destinations])

  const [step, setStep] = useState(1);

  const isNextDisabled = useMemo(() => {
    if (selectedDestinations.length === 0) return true;
    return false;
  }, [selectedDestinations]);

  const isNextDisabled_2 = useMemo(() => {
    if (adult_tickets === 0
        && children_tickets === 0
        || email === ""
        || phone === ""
        || fname === ""
        || lname === ""
        || !date

    ) return true;
    return false;
  }, [ adult_tickets, children_tickets, email, phone, fname, lname, date]);

  return (
    <>
      <Header />
      <Container>
        <div>
          {/* Destination section */}
          {step === 1 && (
              <div className='col-md-12' style={{ display: 'inline-block' }}>
                <h1 className='booking_title'>Choose Your Destinations</h1>
                <div className='row flex-wrap'>
                  {destinations?.map((destination) => {
                    return (
                        <div className='col-md-4 mb-3'>
                          <div className='card h-100'>
                            <div style={{ height: 160, overflow: 'hidden' }}>
                              <img
                                  className='card-img-top'
                                  src={destination.image}
                                  alt='Card cap'
                                  width={230}
                                  height={200}
                              />
                            </div>
                            <div className='card-body' style={{ height: '370' }}>
                              <p style={{height:"150px"}}>{destination.description}</p>
                              <Form.Check
                                  checked={selectedDestinations.includes(
                                      destination.id
                                  )}
                                  type={'checkbox'}
                                  id={destination.destination_id}
                                  onChange={(e) => {
                                    setSelectedDestinations((prev) => {
                                      if (prev.includes(destination.id)) {
                                        return prev.filter((i) => i !== destination.id);
                                      }
                                      return [...prev, destination.id];
                                    });
                                  }}
                              />
                              <div className='destination-details d-flex justify-content-between align-items-center'>
                                <div className='destination-text'>
                                  <b style={{ color: '#450272' }}>
                                    {destination.destination}
                                  </b>
                                </div>
                                <div className='destination-cost'>
                                  <b>${destination.cost}</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                    );
                  })}
                </div>
                <div style={{ marginTop: '35px', marginBottom: '35px' }}>
                  **Price includes private custom tour and 1 attraction (50 USD Cap on the cost of attraction). The passenger is responsible for paying for the 2nd attraction.
                </div>
                <div className='col-md-12 mt-3' style={{ marginBottom: '35px', textAlign: 'right' }}>
                  <button
                      disabled={isNextDisabled}
                      className='btn btn-primary paybtn'
                      onClick={() => setStep(2)}
                  >
                    CONTINUE BOOKING
                  </button>
                </div>
              </div>
          )}
          {/* Destination section end */}
          <div>

          </div>
          <div>
            {/* first section start */}
            {step === 2 && (
              <div style={{ marginBottom: '50px' }}>
                <h1 className='booking_title'>ENTER YOUR INFO</h1>
                <h4 className='formheader bg-info'>Booking Information</h4>

                <div className='row'>
                  <div className='col-md-6'>
                    <Form.Group
                      className={'mb-3'}
                      style={{ fontWeight: 'bold' }}
                    >
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        placeholder={'First name'}
                        value={fname}
                        onChange={(e) => setFname(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group
                      className={'mb-3'}
                      style={{ fontWeight: 'bold' }}
                    >
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        placeholder={'Last name'}
                        value={lname}
                        onChange={(e) => setLname(e.target.value)}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group
                      className={'mb-3'}
                      style={{ fontWeight: 'bold' }}
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        name={'phone'}
                        placeholder={'000-000-0000'}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        required
                      />
                    </Form.Group>
                  </div>
                  <div className='col-md-6'>
                    <Form.Group
                      className={'mb-3'}
                      style={{ fontWeight: 'bold' }}
                    >
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        placeholder={'email@email.com'}
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                        required
                      />
                    </Form.Group>
                  </div>


                  <div className='col-md-4' style={{ marginTop: '40px' }}>
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Adult
                    </Form.Label>
                    <Form.Select
                      value={adult_tickets}
                      onChange={(e) =>
                        setAdultTickets(parseInt(e.target.value))
                      }
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                    </Form.Select>
                  </div>

                  <div className='col-md-4' style={{ marginTop: '40px' }}>
                    <Form.Label style={{ fontWeight: 'bold' }}>
                      Children
                    </Form.Label>
                    <Form.Select
                      value={children_tickets}
                      onChange={(e) =>
                        setChildrenTickets(parseInt(e.target.value))
                      }
                    >
                      <option>0</option>
                      <option>1</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                    </Form.Select>
                  </div>

                  <div className='col-md-4' style={{ marginTop: '40px' }}>
                    <Form.Label style={{ fontWeight: 'bold' }}>Date</Form.Label>
                    <Form.Control
                      type={'date'}
                      value={date}
                      required
                      onChange={(e) => setDate(e.target.value)}
                    />
                  </div>

                  <div className='col-md-12 mt-3' style={{ textAlign:'right'}}>
                    <br/><br/>
                    <button
                        className='btn btn-primary paybtn'
                        style={{backgroundColor:'darkred', marginRight:'15px'}}
                        onClick={() => {setStep(1)}}
                    >
                      GO BACK
                    </button>
                    <button
                      disabled={isNextDisabled_2}
                      className='btn btn-primary paybtn'
                      onClick={() => {isNextDisabled? toast.error("Please complete all fields to move to next step") : console.log(); setStep(3)}}
                    >
                      CONTINUE TO PAYMENT
                    </button>
                    <br/><br/>
                  </div>
                </div>
              </div>
            )}

            {/* first section end */}

            {/* first section start*/}
            {step === 3 && (
              <div>
                <h1 className='booking_title'>ENTER PAYMENT INFO</h1>
                <div className={'paycol'} style={{ padding: '50px' }}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <h4 className='formheader bg-info'>
                        Payment Information
                      </h4>

                      <div className='row ' style={{ marginTop: '0px' }}>
                        <div className='col-md-12 my-2'>
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Cardholder Name As Appears On Card
                          </Form.Label>
                          <Form.Control
                              placeholder={'Cardholder Name'}
                              onChange={(e) => setCardname(e.target.value)}
                              disabled={disabled}
                              aria-required={true}
                              value={cardname}
                          />
                        </div>
                        <div className='col-md-12 my-2'>
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Credit/Debit Card number
                          </Form.Label>
                          <Form.Control
                            placeholder={'0000 0000 0000 0000'}
                            onChange={(e) => setCardnum(e.target.value)}
                            disabled={disabled}
                            aria-required={true}
                            value={cardnum}
                          />
                        </div>
                        <div className='col-md-12 my-2'>
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Exp month
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCardmonth(e.target.value)}
                            disabled={disabled}
                            value={cardmonth}
                          >
                            <option>Select</option>
                            {Array.from(new Array(12)).map((_, i) => {
                              const value = i + 1 >= 10 ? i + 1 : '0' + (i + 1);
                              return <option value={value}>{value}</option>;
                            })}
                          </Form.Select>
                        </div>

                        <div className='col-md-12 my-2'>
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            Exp Year
                          </Form.Label>
                          <Form.Select
                            onChange={(e) => setCardyear(e.target.value)}
                            disabled={disabled}
                            value={cardyear}
                          >
                            <option>Select</option>
                            {Array.from(new Array(12)).map((_, i) => {
                              const value = i + 23;
                              return <option>20{value}</option>;
                            })}
                          </Form.Select>
                        </div>

                        <div className='col-md-12 mt-2 mb-5'>
                          <Form.Label style={{ fontWeight: 'bold' }}>
                            CVC
                          </Form.Label>
                          <Form.Control
                            disabled={disabled}
                            onChange={(e) => setCardcvc(e.target.value)}
                            placeholder={'000'}
                            value={cardcvc}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <h4 className='formheader'>Cart Items</h4>
                      <div className={'totals'} style={{ padding: '15px' }}>
                        <Row>
                          <Col>{adultText}</Col>
                          <Col>${adultTotal}</Col>
                        </Row>

                        <Row>
                          <Col>{childText}</Col>
                          <Col>${childTotal}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col>Total</Col>
                          <Col>${total}</Col>
                        </Row>

                        <div
                          className='checkbox pcheck'
                          style={{ marginTop: '10px' }}
                        >
                          <label htmlFor='destinations-1'>
                            <input
                              type='checkbox'
                              value={cash}
                              checked={cash}
                              onChange={handleDisabled}
                              id={'destinations-1'}
                            />
                            <span style={{ fontSize: 20 }}>
                              &nbsp; Pay with with cash
                            </span>
                          </label>
                          <p
                            style={{
                              fontSize: 16,
                              margin: 0,
                              marginTop: '5px',
                            }}
                          >
                            By paying with cash I agree to advise my tour guide
                            30 minutes ahead of time that I need to complete
                            payment
                          </p>
                        </div>

                        <div
                          className='checkbox pcheck'
                          style={{ marginTop: '0' }}
                        >
                          <label htmlFor='termsAndConditions'>
                            <input
                              type='checkbox'
                              value={terms}
                              onChange={() => setTerms((p) => !p)}
                              id={'termsAndConditions'}
                            />
                            <span style={{ fontSize: 20 }}>
                              &nbsp; I agree with
                              <a href='/' className='btn primary'>
                                terms and conditions
                              </a>
                              <br />
                              <b>All payments are in USD</b>
                            </span>
                          </label>
                        </div>

                        <div className='d-flex flex-wrap gap-3 mt-3'>
                          <button
                            className='btn btn-danger'
                            onClick={() => setStep(2)}
                          >
                            Go Back
                          </button>
                          <button
                            type={'submit'}
                            className={'btn btn-primary'}
                            onClick={handleSubmit}
                          >
                            CONTINUE WITH PAYMENT
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* second section end */}
          </div>
        </div>
      </Container>
      <Footer />
    </>
  );
};
export default Bookings;
