import React from 'react';
import Header from "./Header";
import Footer from "./Footer";
import {Accordion, Container} from "react-bootstrap";

const Faqs = () => {
    return (
        <div>
            <Header/>
            <Container>
                <div className="booking_title">FAQs</div>

                <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Q: What does the cost include?</Accordion.Header>
                        <Accordion.Body>
                            <b>Answer:</b> Rates shown include transportation, vehicle, tour guide and
                            1 attraction (50 USD Cap on the cost of attraction).
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header>Q: What is your cancellation policy?</Accordion.Header>
                        <Accordion.Body>
                            We realize that mishaps happen, therefore, we will assist as best as
                            possible. A full refund will be extended to you if notice of cancellation is
                            given up to 3 days before the scheduled tour date. If notice of cancellation
                            is received 2 days before the scheduled tour a 50% refund will be extended.
                            There will be no refunds for notice of cancellation that is received 24
                            hours or less before a scheduled tour.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header>Q. What should I bring?</Accordion.Header>
                        <Accordion.Body>
                            <b>Answer:</b> We recommend bringing along a camera, closed-toe shoes, Water shoes,
                            Swimwear, Towels, Change of clothes, Sunscreen, and pocket money.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header>Q. Can I customize a tour?</Accordion.Header>
                        <Accordion.Body>
                            <b>Answer:</b> Yes, If you would like to see an additional attraction that is not part of
                            our package, please contact us directly and we will update your tour
                            package. An updated package is subject to price changes.
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header>Q. How far in advance can I book?</Accordion.Header>
                        <Accordion.Body>
                            <b>Answer:</b> We accept bookings as long as 8 months in advance.
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Container>
            <Footer/>
        </div>
    );
};

export default Faqs;
