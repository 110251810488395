/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { v4 as uuid } from 'uuid';
// import required modules
import { Navigation, Pagination } from 'swiper';
import {Outlet} from "react-router-dom";

export default function VisitedPalaceSlider() {
  return (
    <>
      <Swiper
        slidesPerView={1}
        navigation={true}
        pagination={false}
        modules={[Navigation, Pagination]}
        className='mySwiper'
        spaceBetween={30}
        loop
        autoplay={{
          delay: 4000,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
          },
          991: {
            slidesPerView: 3,
          },
        }}
      >
        {DATA.map((item) => (
          <SwiperSlide key={item.id}>
            <div className='col-md-12'>
              <div className='card mb-2'>
                <img
                  className='card-img-top'
                  src={item.image}
                  height='232'
                  alt='Card  cap'
                />
                <div className='card-body'>
                  <h4 className='card-title'>{item.title}</h4>
                  <p className='card-text'>{item.subtitle}</p>
                  <a className='btn btn-primary' href={item.link}>
                    {item.linkText}
                  </a>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <Outlet/>
    </>
  );
}

const DATA = [
  {
    id: uuid(),
    image: 'images/general/bobmarleymuseum.jpg',
    title: 'Bob Marley Museum',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: 'Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/dunnsriver.jpg',
    title: 'Dunns River Falls',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: 'Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/bostonjerkcenter.jpg',
    title: 'Boston Jerk Center',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/puertoseco.jpg',
    title: 'Puerto Seco Beach',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/portroyal.jpg',
    title: 'Port Royal',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/hopegarden.jpg',
    title: 'Hope Botanical Garden',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/devonhouse.jpg',
    title: 'Devon House',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
  {
    id: uuid(),
    image: 'images/general/bluelagoon.jpg',
    title: 'Blue Lagoon',
    subtitle: '**Price includes private custom tour and 1 attraction. The passenger is responsible for paying for the 2nd attraction.',
    link: '/bookings',
    linkText: ' Book Tour Now',
  },
];
