import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import Footer from './Footer';
import Header from './Header';

const Success = () => {
  let location = useLocation();

  const [confirmation, setConfirmation] = useState('');

  useEffect(() => {
    if (location.state != null) {
      setConfirmation(location.state.confirmation_s);
    } else {
      toast.error(
        'Confirmation is null please reach out to customer service to complete booking process' +
          confirmation
      );
    }
  }, [confirmation, location]);

  return (
    <>
      <Header />
      <Container>
        <div style={{ padding: '0' }}>
          <h1 className='booking_title'>Your Booking is Confirmed!</h1>
          <h2>
            <b>Confirmation No.</b>
          </h2>
          <h1>{confirmation.toUpperCase()} </h1> <br />
          <div style={{marginBottom:"100px"}}> A receipt has been sent to your email.</div>

          {/*Adults $Adults*/}
          {/*Childres $chil*/}
          {/*Total $total*/}
          {/*Tour Date $date*/}
          {/*<h3>Destinations</h3>*/}
          {/*<h3>Instructions</h3>*/}
          {/*1. Pack Bags*/}
          {/*2. Make sure you have sunscreeen*/}
          {/*3. Call office in advance*/}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Success;
