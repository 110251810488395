import React from 'react';
import {Nav} from "react-bootstrap";
import {LinkContainer} from "react-router-bootstrap";

const Footer = () => {
    return (
        <footer id="main-footer">
            <div className="container">
                <div className="row row-wrap">
                    <div className="col-md-3">
                        <a className="logo" href="index.html">
                            <img src="images/logo/logobg.png" alt="Logo Image" width="50%"/>
                        </a>
                        <p className="mb20">Booking, reviews and advices on hotels, resorts, flights, vacation rentals,
                            travel packages, and lots more!</p>
                        <a href="https://www.facebook.com/profile.php?id=100091668991114&mibextid=LQQJ4d" className="footerlinki fa fa-facebook icons"></a>
                        <a href="https://twitter.com/catchavibesbolt?s=21" className="footerlinki fa fa-twitter icons"></a>
                        <a href="#" className="footerlinki fa fa-google icons"></a>
                        <a href="#" className="footerlinki fa fa-youtube icons"></a>
                        <a href="https://www.instagram.com/p/CqgFqIEvTkL/?igshid=YmMyMTA2M2Y=" className="footerlinki fa fa-instagram icons"></a>
                        <a href="#" className="footerlinki fa fa-pinterest icons"></a>
                    </div>
                    <div className="col-md-3">
                        <h4>Newsletter</h4>
                        <form>
                            <label>Enter your E-mail Address</label>
                            <input type="text" className="form-control"/>
                                <p className="mt5"><small>*We Never Send Spam</small>
                                </p>
                                <input type="button" className="btn btn-primary" value="Subscribe"/>
                        </form>
                    </div>
                    <div className="col-md-2">
                        <ul className="list list-footer ">
                            <li>
                                <LinkContainer to="/faqs">
                                    <Nav.Link className={'footerlink'}>FAQs</Nav.Link>
                                </LinkContainer>
                            </li>

                            <li>
                                <LinkContainer to="/">
                                    <Nav.Link className={'footerlink'}>Jobs</Nav.Link>
                                </LinkContainer>
                            </li>
                            <li>
                                <LinkContainer to="/terms">
                                    <Nav.Link className={'footerlink'}>Privacy Policy</Nav.Link>
                                </LinkContainer>
                            </li>
                            <li>
                                <LinkContainer to="/terms">
                                    <Nav.Link className={'footerlink'}>Terms of Use</Nav.Link>
                                </LinkContainer>
                            </li>
                        </ul>
                    </div>
                    <div className="col-md-4">
                        <h4>Have Questions?</h4>
                        <h4 className="text-color">Local : <br/>876-384-7033</h4>
                        <h4 className="text-color">International : <br/> +1-585-358-9545</h4>
                        <h4><a href="#" className="text-color footerlink">bolttourscs@gmail.com</a></h4>
                        <p>Dedicated Customer Support</p>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
