import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './animations.css';
import './App.css';
import Bookings from './Bookings';
import Contact from './Contact';
import Faqs from './Faqs';
import Home from './Home';
import LocateBooking from './LocateBooking';
import Success from './Sucess';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import Terms from "./Terms";
import Hotel from "./Hotel";
function App() {
  return (
    <BrowserRouter>
      <ToastContainer />
      <Routes>
        <Route path='/'>
          <Route index element={<Home />} />
          <Route path='/bookings' element={<Bookings />} />
          <Route path='/locateBooking' element={<LocateBooking />} />
          <Route path='/faqs' element={<Faqs />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/terms' element={<Terms />} />
          <Route path='/success' element={<Success />} />
          <Route path='/hotel' element={<Hotel />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
