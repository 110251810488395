import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import { toast } from 'react-toastify';
import { Container } from 'react-bootstrap';
import axios from 'axios';

const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!name || !email || !message) {
            toast.error('Please fill in all fields.');
            return;
        }

        // Send form data to the server-side endpoint
        axios
            .post('http://127.0.0.1:8000/api/contact', {
                name: name,
                email: email,
                message: message,
            },{
                'Access-Control-Allow-Headers': '*',
                'Access-Control-Allow-Methods': '*',
                'Access-Control-Allow-Origin': '*',
                'Content-Type': 'application/json',
            })
            .then((response) => {
                toast.success('Message sent successfully!');
                // Clear form fields after successful submission
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((error) => {
                toast.error('Failed to send message. Please try again later.');
            });
    };

    return (
        <>
            <Header />
            <Container>
                <div className="booking_title mb-4">Contact us</div>

                <div className="row mb-4">
                    <div className="col-sm-12 col-md-6 col-lg-3 my-3">
                        <div className="card border-0">
                            <div className="card-body text-center">
                                <i className="fa fa-phone fa-5x mb-3" aria-hidden="true"></i>
                                <h4 className="text-uppercase mb-3">Call Us</h4>
                                <div>
                                    <b>Local</b>
                                    <p>876-384-7033</p>

                                    <b>Montego Bay Office</b>
                                    <p>876-342-6736</p>
                                </div>
                                <div>
                                    <b>International</b>
                                    <p>+1-585-358-9545</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 my-3">
                        <div className="card border-0">
                            <div className="card-body text-center">
                                <i className="fa fa-fax fa-5x mb-3" aria-hidden="true"></i>
                                <h4 className="text-uppercase mb-3">Fax</h4>
                                <p><b>Unavailable</b></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 my-3">
                        <div className="card border-0">
                            <div className="card-body text-center">
                                <i className="fa fa-map-marker fa-5x mb-3" aria-hidden="true"></i>
                                <h4 className="text-uppercase mb-3">Office Locations</h4>
                                <address>Montego Bay, Ocho Rios</address>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 my-3">
                        <div className="card border-0">
                            <div className="card-body text-center">
                                <i className="fa fa-globe fa-5x mb-3" aria-hidden="true"></i>
                                <h4 className="text-uppercase mb-3">Email</h4>
                                <p>bolttourscs@gmail.com</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="booking_title mb-4">Write Us</div>*/}

            {/*    <form style={{ maxWidth: '600px', margin: '0 auto' }} onSubmit={handleSubmit}>*/}
            {/*        <div className="form-group">*/}
            {/*            <input*/}
            {/*                className="form-control"*/}
            {/*                type="text"*/}
            {/*                name="name"*/}
            {/*                placeholder="Name"*/}
            {/*                value={name}*/}
            {/*                onChange={(e) => setName(e.target.value)}*/}
            {/*                required*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <br />*/}
            {/*        <div className="form-group">*/}
            {/*            <input*/}
            {/*                className="form-control"*/}
            {/*                type="email"*/}
            {/*                name="email"*/}
            {/*                placeholder="Email"*/}
            {/*                value={email}*/}
            {/*                onChange={(e) => setEmail(e.target.value)}*/}
            {/*                required*/}
            {/*            />*/}
            {/*        </div>*/}
            {/*        <br />*/}
            {/*        <div className="form-group">*/}
            {/*<textarea*/}
            {/*    className="form-control"*/}
            {/*    rows="6"*/}
            {/*    name="message"*/}
            {/*    placeholder="Message"*/}
            {/*    value={message}*/}
            {/*    onChange={(e) => setMessage(e.target.value)}*/}
            {/*    required*/}
            {/*/>*/}
            {/*        </div>*/}
            {/*        <br />*/}
            {/*        <div className="form-group text-center">*/}
            {/*            <button className="btn btn-primary" type="submit" style={{ width: '100%' }}>*/}
            {/*                SEND*/}
            {/*            </button>*/}
            {/*        </div>*/}
            {/*    </form>*/}
            </Container>
            <br/>
            <Footer />
        </>
    );
};

export default Contact;
