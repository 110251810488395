import React from 'react';
import {Container, Navbar, Nav, NavLink, NavDropdown} from "react-bootstrap";
import { Outlet } from "react-router-dom";
import {LinkContainer} from 'react-router-bootstrap'


const Header = () => {
    return (
<>
            <Navbar bg="dark" variant="dark" expand="lg" sticky="top" >
                <Container>
                    <Navbar.Brand href="#home"><img src="images/logo/logosm.png" width={"250px"}/></Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" >
                        <Nav className="ms-auto">
                            <LinkContainer to="/">
                                <Nav.Link>Home</Nav.Link>
                            </LinkContainer>
                            <NavDropdown title="Booking" id="collasible-nav-dropdown">
                                <LinkContainer to="/bookings">
                                    <NavDropdown.Item>Book a Tour</NavDropdown.Item>
                                </LinkContainer>

                                <LinkContainer to="/hotel">
                                    <NavDropdown.Item>Book Accomodation</NavDropdown.Item>
                                </LinkContainer>
                                <LinkContainer to="/locateBooking">
                                    <NavDropdown.Item >Locate Booking</NavDropdown.Item>
                                </LinkContainer>
          
                            </NavDropdown>
                            <LinkContainer to="/faqs">
                                <Nav.Link>FAQs</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/contact">
                                <Nav.Link>Contact</Nav.Link>
                            </LinkContainer>
                        </Nav>
                    </Navbar.Collapse>
                </Container>

            </Navbar>
    <Outlet/>

</>
    );
};

export default Header;
